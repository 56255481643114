import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Endpoint, DISEndpoint, LoginRedirectEndpoint, FileUploadEndpoint } from '../../model/common/endpoint';
import { Environment } from '../../model/common/environment';
import { ItdrsHttpEnvironmentSettings, TDRS_HTTP_ENVIRONMENT_CONFIG } from './tdrs-environment.provider';
import { utilIsNil, utilIsEmpty } from '../../service/library/library';

//
// Window Reference
//
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class TdrsHttpEnvironmentService
  implements ItdrsHttpEnvironmentSettings, OnDestroy {
  //
  // Public getters
  //
  public get name(): string { return this._environment.name; }
  public get hostname(): Endpoint { return this._environment.hostname; }
  public get api(): Endpoint { return this._environment.api; }
  public get asset(): Endpoint { return this._environment.asset; }
  public get dis(): DISEndpoint { return this._environment.dis; }
  public get engageUserDetail(): Endpoint { return this._environment.engageUserDetail; }
  public get video(): Endpoint { return this._environment.video; }
  public get loginredirect(): LoginRedirectEndpoint { return this._environment.loginredirect; }
  public get fileuploadapi(): FileUploadEndpoint { return this._environment.fileuploadapi; }

  //
  // getUrl()
  //
  public getUrl(key: string): Endpoint {
    return this._environment.getUrl(key);
  }

  //
  // getTag()
  //
  public getTag(key: string): string | boolean {
    return this._environment.getTag(key);
  }

  //
  // Private Variables
  //
  public _environment: Environment;

  //
  // ngOnDestroy()
  //
  public ngOnDestroy() {
    //empty method
  }
  //
  // Constructor()
  //
  constructor(@Inject(TDRS_HTTP_ENVIRONMENT_CONFIG) environments: any) {
    //
    // Init hostname
    //
    let hostname: any = null;
    this._environment = new Environment({
      hostname: {
        url: 'localhost'
      }
    });
    //
    // Extract Host Name
    //
    if (!utilIsNil(window.location) &&
      !utilIsNil(window.location.hostname)
    ) {
      hostname = window.location.hostname.toLowerCase();
    }
    //
    // If we have a host name
    //
    if (!utilIsEmpty(hostname)) {
      //
      // Persist the environments
      //
      if (!utilIsNil(environments)) {
        const list = environments.map(env => new Environment(env));
        try {
          //
          // Find Enviroment based on Hostname
          //
          const idx = list.findIndex(
            env => env.hostname.url.toLowerCase() === hostname
          );
          if (idx > -1) {
            this._environment = list[idx];
          }
        } catch (err) {
          throw new Error(`Attempted to access an invalid set of environment variables: ${hostname}`);
        }
      }
    }
  }
}
