import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TdrsHttpEnvironmentService } from '../environment';

@Injectable()
export class EmailService {
    private readonly defaultHeaders: HttpHeaders = new HttpHeaders();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly tdrsHttpEnvironmentService: TdrsHttpEnvironmentService
    ) {}

    public sendEmail(message: any): Observable<any> {
        const path = `${this.tdrsHttpEnvironmentService.api.url}/api/sendemail`;
        
        // Set headers
        const headerParams = this.defaultHeaders.set('Content-Type', 'application/json');

        // Make HTTP POST request
        return this.httpClient.post<any>(path, message, {
            headers: headerParams,
            withCredentials: true
        });
    }
}
