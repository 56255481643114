import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/service/guard/auth.gaurd';

import { ROUTES } from './app-routes.constant';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { HelpcenterComponent } from './feature/helpcenter/helpcenter.component';
import { HomeComponent } from './feature/home/home.component';
import { ReviewGuidelinesComponent } from './feature/review-guidelines/review-guidelines.component';


import { MsalGuard } from './msal';

export const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [
      MsalGuard,
    ],
  },
  { path: '',
    redirectTo: 'home',
    pathMatch: 'full' },
  {
    path: ROUTES.NOT_ALLOWED,
    pathMatch: 'full',
    component: NotFoundComponent,
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.MY_REVIEW,
    pathMatch: 'full',
    loadChildren: () => import('./feature/my-reviews/my-reviews.module').then(m => m.MyReviewsModule)
  },
  {
    path: ROUTES.SEARCH_REVIEW,
    pathMatch: 'full',
    loadChildren: () => import('./feature/search-review/search-review.module').then(m => m.SearchReviewModule),
    canLoad: [AuthGuard]
  },
  {
    path: ROUTES.INITIATE_REVIEW,
    loadChildren: () => import('./feature/new-review/new-review.module').then(m => m.NewReviewModule),
    canLoad: [AuthGuard]
  },
  {
    path: ROUTES.REVIEW_GUIDELINES,
    component: ReviewGuidelinesComponent,
    canActivate: [
      MsalGuard,
    ],
  },
  {
    path: ROUTES.HELP_CENTER,
    component: HelpcenterComponent,
    canActivate: [
      //ChromeGuard,
      MsalGuard
    ],
  },
  {
    path: ROUTES.MANAGE_REVIEW,
    loadChildren: () => import('./feature/manage-review/manage-review.module').then(m => m.ManageReviewModule),
    canLoad: [AuthGuard]
  },
  {
    path: ROUTES.REVIEW_OVERVIEW,
    loadChildren: () => import('./feature/review-process/review-process.module').then(m => m.ReviewProcessModule)
  },
  {
    path: ROUTES.MY_PROFILE,
    loadChildren: () => import('./feature/my-profile/my-profile.module').then(m => m.MyProfileModule)
  },
  {
    path: ROUTES.PUBLISH_REVIEW,
    loadChildren: () => import('./feature/new-review/publish-review/publish-review.module').then( m => m.PublishReviewModule),
    canLoad: [AuthGuard]
  },
  {
    path: ROUTES.ADD_REMOVE_REVIEWER,
    loadChildren: () => import('./feature/add-remove-reviewers/add-remove-reviewers.module').then( m => m.AddRemoveReviewersModule),
    canLoad: [AuthGuard]
  },
  {
    path: ROUTES.ADMIN_USER_MANAGEMENT,
    loadChildren: () => import('./feature/admin-user-management/admin-user-management.module').then( m => m.AdminUserManagementModule),
    canLoad: [AuthGuard]
  },
  {
    path:ROUTES.HOLIDAY_LISTS,
    loadChildren:()=> import('./feature/holiday-management-lists/holiday-management-lists.module').then(m=>m.HolidayManagementListsModule),
  },
  {
    path:ROUTES.MANAGE_MODEL,
    loadChildren:()=> import('./feature/manage-model/manage-model.module').then(m=>m.ManageModelModule),
    canLoad: [AuthGuard]
  },
  {
    path:ROUTES.MANAGE_DEPARTMENT,
    loadChildren:()=> import('./feature/manage-department/manage-department.module').then(m=>m.ManageDepartmentModule),
    canLoad: [AuthGuard]
  },
  {
    path:ROUTES.MANAGE_MODEL_YEAR,
    loadChildren:()=> import('./feature/manage-model-year/manage-model-year.module').then(m=>m.ManageModelYearModule),
    canLoad: [AuthGuard]
  } 
];

export const appRouting: ModuleWithProviders<any> = RouterModule.forRoot(
  appRoutes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' });
