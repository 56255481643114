'use strict';
import { utilGet,utilIsNil } from '../../service/library/library';
import { ROUTES } from '../../app-routes.constant';
import { PublicRoute, ROLES } from '../../app.constant';
import * as library from '../../service/library/library';

// Model for engage user detail
export class EngageUserDetail {
  public isAuthenticated: boolean;
  public user: UserDetail;

  constructor(options?: any) {
    this.isAuthenticated = !utilIsNil(options.isAuthenticated)
      ? options.isAuthenticated : false;
    if (!utilIsNil(options.user)) {
      this.user = new UserDetail(options.user);
    }
    else {
      this.user = null;
    }
  }

}

export class UserDetail {
  public nameID: string;
  public nameIDFormat: string;
  public dealerCode: string;
  public spinId: string;
  public regionCode: string;
  public deactivated: boolean;
  public email: string;
  public dealerName?: string;
  public message?: string;
  public firstName: string;
  public lastName: string;
  public isCorporate: string;
  constructor(options: any) {
    this.nameID = library.init(options, 'nameID', '');
    this.nameIDFormat = library.init(options, 'nameIDFormat', '');
    this.dealerCode = library.init(options, 'dealerCode', '');
    this.spinId = library.init(options, 'spinId', '');
    this.regionCode = library.init(options, 'regionCode', '');
    this.deactivated = library.init(options, 'deactivated', false);
    this.email = library.init(options, 'email', '')
  }
}

export interface IUserLoggedInInfo {
  groups: string[]
  name: string
  roles: string[]
  username: string
  workdayId: string,
  brand: string[],
  userid: string,
  conceptusergroup?: string,
  email?: string,
  firstName?: string,
  lastName?: string
}

export class UserLoggedInInfo implements IUserLoggedInInfo {
  groups: string[];
  name: string;
  roles: string[];
  username: string;
  workdayId: string;
  brand: string[];
  userid: string;
  conceptusergroup: string;
  email: string
  firstName = '';
  lastName = '';
  constructor(options: IUserLoggedInInfo) {
    this.groups = options.groups || [];
    this.name = options.name || '';
    this.roles = options.roles || [];
    this.username = options.username || '';
    this.workdayId = options.workdayId || '';
    this.brand = options.brand || [];
    this.userid = options.userid || '';
    this.conceptusergroup = options.conceptusergroup || '';
    this.email = options?.email || '';
    this.firstName = utilGet(options, 'firstName', '');
    this.lastName = utilGet(options, 'lastName', '');
  }
}

export const UserRoles = {
  SuperUser: 'Project Review Super User',
  ReviewLeader: 'Project Review Review Leader',
  Reviewer: 'Project Review Reviewer',
  LegalReviewer: 'Project Review Legal Reviewer',
  Agency_GraphicsPartner: 'Project Review Agency/Graphics Partner'
}

export const RoleNames = {
  SuperUser: 'ProjectReview.SuperUser',
  ReviewLeader: 'ProjectReview.ReviewLeader',
  Reviewer: 'ProjectReview.Reviewer',
  LegalReviewer: 'ProjectReview.LegalReviewer',
  Agency_GraphicsPartner: 'ProjectReview.Agency/GraphicsPartner'
}

const userGroupMap = new Map();
userGroupMap.set(RoleNames.SuperUser, UserRoles.SuperUser);
userGroupMap.set(RoleNames.ReviewLeader, UserRoles.ReviewLeader);
userGroupMap.set(RoleNames.Reviewer, UserRoles.Reviewer);
userGroupMap.set(RoleNames.LegalReviewer, UserRoles.LegalReviewer);
userGroupMap.set(RoleNames.Agency_GraphicsPartner, UserRoles.Agency_GraphicsPartner);

export const UserGroupMap = userGroupMap;

export interface IGraphProfile {
  '@odata.context': string
  businessPhones: any[]
  displayName: string
  givenName: string
  jobTitle: any
  mail: string
  mobilePhone: any
  officeLocation: any
  preferredLanguage: any
  surname: string
  userPrincipalName: string
  id: string
}

export const RouteMap = new Map();
RouteMap.set(ROUTES.ADMIN_USER_MANAGEMENT, [ROLES.ADMIN]);
RouteMap.set(ROUTES.MANAGE_REVIEW, [ROLES.ADMIN, ROLES.REVIEW_ADMIN, ROLES.AGENCY]);
RouteMap.set(ROUTES.MY_REVIEW, Object.values(ROLES));
RouteMap.set(ROUTES.SEARCH_REVIEW, Object.values(ROLES));
RouteMap.set(ROUTES.INITIATE_REVIEW, [ROLES.REVIEW_ADMIN, ROLES.AGENCY]);
RouteMap.set(ROUTES.PUBLISH_REVIEW, [ROLES.REVIEW_ADMIN]);
RouteMap.set(ROUTES.ADD_REMOVE_REVIEWER, [ROLES.REVIEW_ADMIN]);
RouteMap.set(ROUTES.REVIEW_GUIDELINES, Object.values(ROLES));
RouteMap.set(ROUTES.HELP_CENTER, Object.values(ROLES));
RouteMap.set(ROUTES.MY_PROFILE, Object.values(ROLES));
RouteMap.set(ROUTES.HOME, Object.values(ROLES));
RouteMap.set(ROUTES.MANAGE_MODEL, [ROLES.ADMIN]);
RouteMap.set(ROUTES.MANAGE_DEPARTMENT, [ROLES.ADMIN]);
RouteMap.set(ROUTES.MANAGE_MODEL_YEAR, [ROLES.ADMIN]);
RouteMap.set(PublicRoute, [
  ROUTES.HOME,
  ROUTES.MY_PROFILE,
  ROUTES.HELP_CENTER,
  ROUTES.REVIEW_GUIDELINES
]);

export function checkRouteHasAccess(routePath, role) {
  if (RouteMap.has(routePath)) {
    const rolesArr: Array<any> = RouteMap.get(routePath);
    if (rolesArr && rolesArr.some((x) => role.some((y) => y.includes(x)))) {
      return true;
    }
  }
  return false;
}

export function isPublicRoute(routePath) {
  return RouteMap.get(PublicRoute).some((x) => x === routePath);
}
