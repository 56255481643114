import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../../app-routes.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '../../../../src/app/msal';
import { UserDetailService } from '../../../app/service/common/user/user-detail.service';
import { MenuList, TAB_PROFILE_MENU } from '../../../../src/app/app.constant';
import { checkRouteHasAccess, isPublicRoute } from '../../../app/model/engage/user-detail';

@Component({
  selector: 'dd-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss']
})
export class TabMenuComponent implements OnInit {
  public menuLinks: any;
  public pathSelected = 'home';
  public readonly routeConst = ROUTES;
  public userName = '';
  public get letterAvatar() {
    if (this.userName) {
      const nameArr = this.userName.split(' ');
      if (nameArr.length > 1) {
        return nameArr[0][0]?.toUpperCase()+nameArr[1][0]?.toUpperCase();
      } else if (nameArr.length === 1) {
        return nameArr[0][0]?.toUpperCase();
      } else {
        return '';
      }
    }
    return '';
  }
  public tabProfileMenu = TAB_PROFILE_MENU;
  public isSuperAdmin = false;

  public get activeLink(): string {
    return this.router.url.slice(1).split('?')[0].split('#')[0];
  }

  constructor(public readonly router: Router, private readonly route: ActivatedRoute, public msalService: MsalService,
    public userDetailService: UserDetailService) { }

  menuClick(pathName: string) {    
    switch (pathName) {
      case this.tabProfileMenu.Logout:
        this.msalService.logout();
        localStorage.removeItem('token');
        break;
      case this.tabProfileMenu.Profile:
        this.router.navigateByUrl(ROUTES.MY_PROFILE);
        break;
      case this.tabProfileMenu.UserManagement:
        this.router.navigateByUrl(ROUTES.ADMIN_USER_MANAGEMENT);
        break;
      case this.tabProfileMenu.HolidayLists:
        this.router.navigateByUrl(ROUTES.HOLIDAY_LISTS);
        break;
      case this.tabProfileMenu.ManageModel:
        this.router.navigateByUrl(ROUTES.MANAGE_MODEL);
        break;
      case this.tabProfileMenu.ManageDepartment:
        this.router.navigateByUrl(ROUTES.MANAGE_DEPARTMENT);
        break;
      case this.tabProfileMenu.ManageModelYear:
        this.router.navigateByUrl(ROUTES.MANAGE_MODEL_YEAR);
        break;
      default:
        break;
    }
  }
  ngOnInit() {
    this.menuLinks = [
      {
        'title': '',
        'href': ROUTES.HOME,
        'alt': 'home',
        'icon': 'home'
      },
      {
        'title': 'Manage Review',
        'href': ROUTES.MANAGE_REVIEW,
        'alt': 'Manage_Review'
      },
      {
        'title': 'My Reviews',
        'href': ROUTES.MY_REVIEW,
        'alt': 'my-reviews'
      },
      {
        'title': MenuList.searchReview,
        'href': ROUTES.SEARCH_REVIEW,
        'alt': 'search-reviews',
      },
      {
        'title': 'Initiate Review',
        'href': ROUTES.INITIATE_REVIEW,
        'alt': 'Initiate_Review'
      },
      {
        'title': 'Review Guidelines',
        'href': ROUTES.REVIEW_GUIDELINES,
        'alt': 'legal'
      },
      {
        'title': 'Help Center',
        'href': ROUTES.HELP_CENTER,
        'alt': 'help-center'
      }

    ];
    this.userDetailService.getUserDetails().subscribe(user => {
      const firstLastName = `${user?.firstName?.split(' ')?.join('_')} ${user?.lastName?.split(' ')?.join('_')}`;
      this.userName = firstLastName || user?.name || '';
    });
    this.isSuperAdmin = this.userDetailService?.isSuperAdmin();
  }

  checkRouteAccess(path) {
    if (isPublicRoute(path)) {
      return true;
    }
    if (!this.userDetailService.isActiveUser()) {
      return false;
    }
    const userRole = this.userDetailService.loggedInUserDetailData.groups;
    return checkRouteHasAccess(path, userRole);
  }

}
