import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditLegalReviewService } from '../../service/features/legal-review/add-edit-legal-review.service';
import { utilGet } from '../../service/library/library';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StatusCode } from '../../app.constant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ROUTES } from '../../app-routes.constant';

@Component({
  selector: 'dd-add-edit-legal-review',
  templateUrl: './add-edit-legal-review.component.html',
  styleUrls: ['./add-edit-legal-review.component.scss']
})
export class AddEditLegalReviewComponent implements OnInit {

  public showSpinner = false;
  public legalReviewers: any = {
    column1: [],
    column2: []
  };
  public selectedUsers: any;
  public reviewId: any;
  public brabd: any;
  public legalReviewData = {
    selectedLegalReviewers: [],
    legalReviewers: []
  };
  public readonly columnCount = 2;
  public userId = '';
  public isUnSelectLoggedInUser = false;

  constructor(
    public legalreviewService: AddEditLegalReviewService,
    public dialogRef: MatDialogRef<AddEditLegalReviewComponent>,
    public snackBar: MatSnackBar,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit(): void {
    if (this.dialogData?.reviewDeatil?.brand && this.dialogData?.reviewDeatil?.reviewid) {
      this.getLegalReviewDetails(this.dialogData.reviewDeatil);
    }
    this.reviewId = this.dialogData?.reviewDeatil?.reviewid;
    this.userId = this.dialogData?.userId;
  }

  /**
   * Function to get the Legal reviewers details from API.
   * @param reviewDeatil Review ID and Brand.
   */
  getLegalReviewDetails(reviewDeatil) {
    this.showSpinner = true;
    this.legalreviewService.getLegalReviewDetail({
      reviewId: reviewDeatil.reviewid, brand: reviewDeatil.brand
    }).subscribe((res: any) => {
      if (utilGet(res, 'body.data') && res?.body?.statusCode === StatusCode.Success) {
        this.legalReviewData = utilGet(res, 'body.data', []);
        const selectedLegalReviewers = utilGet(res, 'body.data.selectedLegalReviewers', []);
        let legalReviewersArr = utilGet(res, 'body.data.legalReviewers', []);
        legalReviewersArr = this.mapSelecteUsers(legalReviewersArr, selectedLegalReviewers);
        const indexToSplit = Math.round(legalReviewersArr.length / this.columnCount);
        this.legalReviewers.column1 = legalReviewersArr.slice(0, indexToSplit);
        this.legalReviewers.column2 = legalReviewersArr.slice(indexToSplit);
      } else {
        this.showMessage(res?.body?.message, 5000);
      }
      this.showSpinner = false;
    }, (error:any) => {
      this.showMessage(error?.message, 5000);
      this.showSpinner = false;
    });
  }

  /**
   * Function to map the selected users and disabled the commented users.
   * @param legalReviewers Legal reviewers list
   * @param selectedLegalReviewers Slected legal reviewers.
   */
  mapSelecteUsers(legalReviewers: Array<any>, selectedLegalReviewers: Array<any>) {
    legalReviewers = legalReviewers.map(reviewer => {
      const isSelected = selectedLegalReviewers.some((x) => reviewer?.userid.toString() === x.userid.toString());
      const selectedUser = selectedLegalReviewers.find((x) => reviewer?.userid.toString() === x.userid.toString());
      return {
        ...reviewer,
        selected: isSelected,
        disabled: selectedUser?.commentstatus
      };
    });
    return legalReviewers;
  }

  /**
   * Event emitter function for Mat-Checkbox on value changes
   * @param event MatCheckboxChange
   */
  onCheckChange(event: MatCheckboxChange) {
    if (utilGet(event, 'source.value.userid', null) === this.userId) {
      this.isUnSelectLoggedInUser = !event?.checked;
    }
  }

  /**
   * Function to submit the Legal reviewer changes to service.
   */
  submitForm() {
    const selectedUsers = [...this.legalReviewers.column1, ...this.legalReviewers.column2].filter(x => x?.selected);
    const removedUsers = this.legalReviewData?.selectedLegalReviewers
      .filter((x) => !selectedUsers.some(y => x?.userid === y?.userid));
    const body = {
      reviewId: this.reviewId,
      selectedUsers: selectedUsers.map(x => x?.userid),
      removedUsers: removedUsers.map(x => x?.userid)
    };
    this.showSpinner = true;
    this.legalreviewService.postLegalReviewDetail({ body }).subscribe(res => {
      if (res?.statusCode === StatusCode.Success && this.isUnSelectLoggedInUser) {
        this.closeDialog(false);
        this.router.navigateByUrl(ROUTES.MY_REVIEW);
      } else if (res?.statusCode === StatusCode.Success) {
        this.closeDialog(true);
      } else {
        this.showMessage(res?.data || res?.message, 5000);
      }
      this.showSpinner = false;
    }, (err:any) => {
      this.showSpinner = false;
      this.showMessage(err?.message, 5000);
    });
  }

  /**
   * Function to close the dialog
   * @param isUpdated Is changes happened.
   */
  closeDialog(isUpdated = false) {
    this.dialogRef.close({
      isUpdated
    });
  }

  /**
   * Function to show the message in snackbar
   * @param message Message string
   * @param duration Duration in milliseconds.
   */
  showMessage(message, duration = 2000) {
    this.snackBar.open(message, null, {
      duration,
      panelClass: ['mat-toolbar', 'mat-primary']
    });
  }
}
