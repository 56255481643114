/***
 * Import statements required for http client
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs';
import { Endpoint } from '../../model/common/endpoint';
import { TdrsHttpEnvironmentService } from '../../service/environment';
import { utilIsString } from '../../service/library/library';

/***
 * Class that is injectable for performing
 * http GET/POST and including authorization headers
 */
@Injectable()
export class tdrsHttpService {
  // To populate http instance
  protected headers: HttpHeaders;
  /***
   * Constructor of http client for performing
   * http client related operations
   */
  constructor(public http: HttpClient,
    protected environment: TdrsHttpEnvironmentService) {
    try {
      this.http = http;
      this.headers = new HttpHeaders();
      this.headers.append('content-type', 'application/json');
      this.headers.append('accept', 'application/json');
    } catch (error) {
      console.error(error);
    }
  }

  /***
   * This method is used to perform HTTP GET
   */
  get(url: string,
    params?: object,
    server?: string,
    cacheAge?: number,
    options?: object): any {
    // if (url.indexOf('rti') > -1) {
    //   options = this.addCustomheaders(options);
    // }
    //
    // Establish Default API Servier
    //
    const endpoint: Endpoint = (server) ? this.environment.getUrl(server) : this.environment.api;
    //
    // Append Suffix
    //
    endpoint.suffix = url;
    const apiURL = this.createUrl(url, endpoint);
    return this.http.get(apiURL, options);
  }

  /***
   * This method is used to perform HTTP POST
   */
  post(url: string, body: any, params?: object,
    server?: string, options?: any, isFileUpload?: boolean) {
    // if (url.indexOf('rti') > -1) {
    //   options = this.addCustomheaders(options);
    // }
    //
    // Establish Default API Servier
    //
    const endpoint: Endpoint = (server) ? this.environment.getUrl(server) : (isFileUpload ? this.environment.fileuploadapi :this.environment.api);
    //
    // Append Suffix
    //
    endpoint.suffix = url;
    //
    // Execute Http Post()
    //
    const api = this.createUrl(url, endpoint);
    return this.http.post(api, body, options)
  }

  /***
   * This method is used to perform HTTP PUT
   */
  put(url: string, body: any, params?: object,
    server?: string, options?: any) {
    // if (url.indexOf('rti') > -1) {
    //   options = this.addCustomheaders(options);
    // }
    //
    // Establish Default API Servier
    //
    const endpoint: Endpoint = (server) ? this.environment.getUrl(server) : this.environment.api;
    //
    // Append Suffix
    //
    endpoint.suffix = url;
    //
    // Execute Http Put()
    //
    const api = this.createUrl(url, endpoint);
    return this.http.put(api, body, options)
  }

  /***
   * This method is used to perform HTTP PUT
   */
  patch(url: string, body: any, server?: string, options?: any) {
    // if (url.indexOf('rti') > -1) {
    //   options = this.addCustomheaders(options);
    // }
    //
    // Establish Default API Servier
    //
    const endpoint: Endpoint = (server) ? this.environment.getUrl(server) : this.environment.api;
    //
    // Append Suffix
    //
    endpoint.suffix = url;
    //
    // Execute Http Put()
    //
    const api = this.createUrl(url, endpoint);
    return this.http.patch(api, body, options)
  }

  /***
   * This method is used to perform HTTP DELETE
   */
  delete(url: string, server?: string, options?: any) {
    // if (url.indexOf('rti') > -1) {
    //   options = this.addCustomheaders(options);
    // }
    //
    // Establish Default API Servier
    //
    const endpoint: Endpoint = (server) ? this.environment.getUrl(server) : this.environment.api;
    //
    // Append Suffix
    //
    endpoint.suffix = url;
    //
    // Execute Http Put()
    //
    const api = this.createUrl(url, endpoint);
    return this.http.delete(api, options)
  }

  /**
   * This method adds custom headers to each request being made from client
   */
  addCustomheaders(options: any): any {
      options.headers.append('X-B3-TraceId', '1234');
      options.headers.append('X-B3-SpanId', '1234');

    return options;
  }

  public createUrl(endpoint: string, server: any): string {
    
    const { port, prefix, secure } = server;
    const domain = server.url || '';
    const Library = {
      isStringWithLength(str) {
        return utilIsString(str) && str.length > 0
      }
    }
    let url = secure ? 'https://' : 'http://';
    url += Library.isStringWithLength(domain) ? domain : '';
    if (Library.isStringWithLength(port)) {
      url += `:${port}`;
    }
  
    if (Library.isStringWithLength(prefix)) {
      url += `/${prefix}`;
    }
  
    if (Library.isStringWithLength(endpoint)) {
      url += `/${endpoint}`;
    }
    
    return url.trim();
  }
}
