import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserLoggedInInfo } from '../model/engage/user-detail';
import { UserDetailService } from '../../app/service/common/user/user-detail.service';
import { utilGet } from '../service/library/library';

@Directive({ selector: '[authorizedIf]' })
export class AuthorizedDirective {
  @Input() authorizedIf: any;
  @Input() authorizedIfExcept: any;
  private hasView = false;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly userService: UserDetailService
  ) { }

  ngOnInit() {
    this.userService.getUserDetails().subscribe((res: UserLoggedInInfo) => {
      let isAuthorized = this.userService.isAuthorized();
      let isRoleAuthorized = true;
      if (utilGet(res, 'groups.length', 0) > 0) {
        if (res && res.groups && this.authorizedIfExcept && Array.isArray(this.authorizedIfExcept) && this.authorizedIfExcept.length) {
          isRoleAuthorized = !res.groups.some((x) => this.authorizedIfExcept.some((y) => x.toLowerCase().includes(y.toLowerCase())));
        } else {
          isRoleAuthorized = true;
        }
  
        if (res && res.groups && this.authorizedIf && Array.isArray(this.authorizedIf) && this.authorizedIf.length) {
          isAuthorized = res.groups.every((x) => this.authorizedIf.some((y) => x.toLowerCase().includes(y.toLowerCase())));
        }
  
        if (isAuthorized && isRoleAuthorized && !this.hasView) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!isAuthorized && this.hasView) {
          this.viewContainerRef.clear();
          this.hasView = false;
        }
      }
    });
  }
}
