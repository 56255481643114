import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { utilIsNil } from '../../service/library/library';


@Injectable({
  providedIn: 'root'
})
export class tdrsNotificationService {
  //
  // Private
  //
  private readonly _bus: Subject<any> = new Subject<any>();
  private _list: any[];
  /**
   * Function: constructor()
   */
  constructor() {
    this._list = [];
  }
  //
  // dispatch()
  //
  public dispatch(message: any) {
    if (!utilIsNil(message)) {
      this._bus.next(message);
      setTimeout(() => {
        this._list.unshift(message);
      }, 15);
    }
  }
  //
  // message()
  //
  public message(): Observable<any> {
    return this._bus.asObservable();
  }
  //
  // Clear Notificaiton Messages
  //
  public clear() {
    this._list.length = 0;
  }
}
