import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupControls } from '../../common/form-controls';
import { NeedHelpService } from '../../service/common/need-help.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { utilGet } from '../../service/library/library';
import { UserDetailService } from '../../service/common/user/user-detail.service';
import { UserLoggedInInfo } from '../../model/engage/user-detail';

@Component({
  selector: 'dd-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss']
})
export class NeedHelpComponent implements OnInit {

  eop: string[] = ['Email', 'Phone Number'];

  public emailRegex: RegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  needHelpForm = new FormGroup({
    contactThrough: new FormControl('Email'),
    name: new FormControl('', [Validators.required, Validators.pattern(/^[^\s][a-zA-Z\s]+[^\s]$/)]),
    problem: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z\s0-9]+/)]),
    reviewNumber: new FormControl('', [Validators.pattern(/[A-Za-z0-9]+/)]),
    email: new FormControl('', [Validators.pattern(this.emailRegex)]),
    phoneNumber: new FormControl('', [Validators.pattern(/[0-9]+/)]),
  });
  public showWarning: string;
  public showSuccessContent: boolean = false;

  public get formControl(): FormGroupControls {
    return this.needHelpForm.controls
  }

  public showSpinner: boolean = false;

  constructor(public needHelpService: NeedHelpService,
    public dialogRef: MatDialogRef<NeedHelpComponent>,
    public snackBar: MatSnackBar,public userDetailService: UserDetailService) {

  }
  ngOnInit(): void {
    this.userDetailService.getUserDetails().subscribe((userDetails: UserLoggedInInfo) => {
      const userName = userDetails?.firstName + ' ' + userDetails?.lastName;
      this.needHelpForm.controls.name.setValue(userName);
    });
  }

  postNeedHelp() {
    this.showWarning = '';
    const formControl: any = this.needHelpForm.controls;
    if (formControl && (formControl.email.value || formControl.phoneNumber.value)) {
      const bodyData = {
        reviewnumber: formControl.reviewNumber.value || '',
        firstname: formControl.name.value.split(' ')[0],
        lastname: formControl.name.value.split(' ').slice(1).join(' '),
        email: formControl.email.value || '',
        phone: formControl.phoneNumber.value || '',
        description: formControl.problem.value || ''
      }

      this.showSpinner = true;
      this.needHelpService.postNeedHelp({ body: bodyData })
        .subscribe((res) => {
          if (utilGet(res, 'statusCode') === 200) {
            this.showSuccessContent = true;
          } else {
            this.showWarning = res.error;
          }
        }, () => {
          this.showWarning = 'Unable to submit request';
        });
    } else {
      this.showWarning = 'Either Phone number or Email is required';
    }
  }
}
